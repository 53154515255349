.app-header {
    position: sticky;
    left: 0px;
    top: 0px;
    right: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: grey;
    height: 60px;
    color: #FFF;
    z-index : 999;
}

.app-header .left {
    flex: 1 1 0%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.app-header .right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.app-header .logo {
    width: auto;
    height: 26px;
    margin-right: 4px;
    margin-left: 20px;
}


.app-header .left i {
    font-size : 18px;
    margin-left : 15px;
    cursor : pointer;
}

.app-header .right .user-block {
    display : flex;
    align-items: center;
    margin-right : 15px;
}

.app-header .right .user-block i {
    font-size : 22px;
    cursor : pointer;
}

.user-popover .rs-popover-arrow {
    display : none !important;
}

.header-dropdown li i {
    margin-right : 5px;
}

.app-header .left h3 {
    font-size : 11px;
    line-height : 30px;
    color : #EEE;
    margin : 0;
    padding : 0;
    font-weight : 500;
    position : relative;
    top : 4px;
    user-select : none;
}

.popover-dropdown li {
    color: black;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 8px;
    transition-duration: .5s;
}
.popover-dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
}

.user-popover {
    position : relative;
    z-index : 9999;
}

.app-content {
    display : flex;
    align-items: flex-start;
    height : fit-content;
}

.app-content .app-drawer {
  background : #1d1d1d;
  position : sticky;
  top : 60px;
  /* left : 0;
  bottom : 0; */
  height : 90vh;
  box-shadow : 0 0 3px 0 #333;
  overflow : hidden;
}


.app-content .app-drawer.opened {
    /* width : 250px; */
}

.app-content .app-drawer {
    width : 60px;
    min-width : 60px;
}

.app-content .app-drawer.closed {
    animation : closeDrawer 0.5s ease-in forwards;
}
.app-content .app-drawer.opened {
    animation : openDrawer 0.5s ease-out forwards;
}


.app-drawer ul {
    list-style : none;
    font-family : "Open Sans";
    font-size : 18px;
    padding : 0;
    margin : 0;
    margin-top : 10px;
    text-align: left;
    margin-left : 20px;
}

.app-drawer ul li {
    /* display : flex; */
    align-items: center;
    color : white;
}

.app-drawer ul li .item-title i, .app-drawer ul li .item-title h2 {
    cursor : pointer;
    transition-duration : 0.5s;
}

.app-drawer ul li .item-title:hover h2, .app-drawer ul li .item-title:hover i {
    /* color : var(--primary-color); */
    color : rgb(191, 176, 176);
    transition-duration : 0.5s;
}

.app-content .app-drawer ul {
    margin-left : 16.5px;
    margin-top : 80px !important;
}

.app-content .app-drawer.opened ul li {
    margin-bottom : 10px !important;
    margin-top : 0 !important;
}

.app-content .app-drawer.opened ul li:first-child {
    margin-top : 10px !important;
}

.app-content .app-drawer ul li i {
    font-size : 20px;
}

.app-content .app-drawer ul li h2 {
    display : none;
}

.app-content .app-drawer.opened ul li h2 {
    display : block;
}

.app-drawer ul li i {
    margin-right : 15px;
    font-size : 17px;
}

.app-drawer ul li h2 {
    font-size : 17px;
    margin : 0;
    padding : 0;
    font-family : "Open Sans";
    font-weight : 500;
    line-height : 40px !important;
}

.app-drawer ul li ul {
    margin : 0;
    padding : 0;
    margin-top : 10px;
    margin-bottom : 10px;
    margin-left : 35px;
}

.app-drawer ul li ul li {
    margin-bottom : 10px;
    font-size : 16px;
    cursor : pointer;
    transition-duration: 0.5s;
}

.app-drawer ul li ul li:hover {
    transition-duration : 0.5s;
    /* color : var(--primary-color) !important; */
}

.app-drawer .item-title {
    display : flex;
    align-items: center;
}

/* Animations */

@keyframes closeDrawer {
    from {
        width : 280px;
    }
    to {
        width : 60px;
    }
}

@keyframes openDrawer {
    from {
        width : 60px;
    }
    to {
        width : 280px;
    }
}

.popover-dropdown {
    list-style: none;
    margin : 0;
    padding : 0;
}

.popover-dropdown li {
    font-size : 15px;
    margin-bottom : 8px;
    cursor : pointer;
    transition-duration: 0.5s;
    color : black;
}

.popover-dropdown li:hover {
    transition-duration: 0.5s;
    color : black;
}

.popover-dropdown li:last-child {
    margin-bottom : 0 !important;
}

.app-content .app-drawer  ul {
    margin-top : 0px !important;
}

.app-content .app-drawer ul li {
    margin-top : 25px;
}

.app-drawer .active {
    color : rgb(191, 176, 176);
    cursor : default !important;
}

@media(max-width : 800px) {
    .app-drawer.opened {
        display : none !important;
    }
    .app-drawer.closed {
        width : 60px !important;
    }
}

.drawer-closed .main-content {
    width : 95% !important;
    max-width : 95% !important;
}

.main-content {
    padding-top : 15px;
    padding-left : 20px;
    width : 95%;
}

.app-content {
    position : relative;
}

.partner-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.create-form {
    margin-top: 15px;
    min-width: 400px;
}

.input-group {
    margin-bottom: 10px;
    text-align: left;
}
.page-title h1 {
    display: flex;
    justify-content: flex-start;
}
.partner-content h1 {
    font-family: "Open Sans";
    font-size: 25px;
    line-height: 40px;
}

.partner-content .main-table {
    height: 400px;
    margin-top: 20px;
    width: 100%;
}

.table-content {
    max-height: 70vh;
    overflow: auto;
}

.user-popover {
    position : absolute;
}