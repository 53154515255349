@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:wght@400;500;600&display=swap');

body {
  font-family : "Open Sans";
}

.app-login {
  width : 100%;
  min-height : 100vh;
  display : flex;
  align-items: center;
  justify-content: center;
  background : url("https://www.xprogaming.com/xpg/img/games/re11.jpg");
  background-size : cover;
  background-repeat: no-repeat;
}

.login-box-overlay {
  background : rgba(0,0,0,.4);
  width : 100vw;
  height : 100vh;
  position : fixed;
  z-index : 999;
  top : 0;
  bottom : 0;
  left : 0;
  right : 0;
}

.login-box {
  min-width : 400px;
  min-height : 30px;
  background : grey;
  position : relative;
  z-index : 10000;
  border-radius : 3px;
  display : flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top : 15px;
  padding-bottom : 20px;
}


.login-box input {

}

.login-box .input-box {
  display : flex;
  flex-direction: column;
  width : 90%;
  margin-bottom : 10px;
  position : relative;
}

.login-box .input-box i {
  position : absolute;
  top : 10px;
  left : 10px;
  font-size : 14px;
}

.login-box .input-box input {
  box-sizing: border-box;
  padding : 8px;
  border-radius : 3px;
  border : 1px solid grey;
  padding-left : 28px;
}

.login-box .input-box input:focus {
  outline : none;
}

.login-box .input-box label {
  font-size : 12px;
}

.login-box h2 {
  /* font-size : 10px; */
  margin : 0;
  padding : 0;
  text-transform: uppercase;
  margin-bottom : 15px;
  font-weight : 800;
}

.login-box h2 img {
  width : 100px;
}

.login-box button {
  padding : 3px;
  min-width : 150px;
  text-transform: capitalize;
  cursor: pointer;
  width : 90%;
  background : #333;
  border : none;
  color : #FFF;
  padding-top : 10px;
  padding-bottom : 10px;
  border-radius : 3px;
  font-size : 14px;
}

.error {
  width : 89.5%;
  background : red;
  color : #FFF;
  padding : 5px;
  box-sizing: border-box;
  border-radius : 3px;
  font-size : 12px;
  margin-bottom : 10px;
}

.disabled_button {
  /* background : #EEE !important; */
  cursor: default;
}

.app-game button { 
  text-align: center;
  display: block;
  margin-top : 10px;
  margin-bottom : 10px;
  background : #FFF;
  border : 1px solid #FFF;
  cursor : pointer;
}

.full-bar {
  background : black;
  display : flex;
  align-items: center;
  justify-content: flex-end;
  padding-right : 10px;
}